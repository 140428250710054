import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Img from 'gatsby-image';
import { FontAwesomeIcon as F } from '@fortawesome/react-fontawesome';
import { faCaretLeft as left, faCaretRight as right } from '@fortawesome/free-solid-svg-icons';
import c from 'classnames';
import { provideData } from '../data';
import MDXPage, { Consumer } from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import FilterPhrase from '../components/FilterPhrase';
import Table from '../components/Table';
import Link from '../components/Link';
import { Decimal, Currency, Percent, Multiple } from '../components/Formatted';
import Header from '../components/Header.mdx';
import StaticNavbar from '../components/StaticNavbar.mdx';
import styles from './index.module.css';
export const getSingleId = (slug, {
  universitiesBySlug
}) => universitiesBySlug[slug];
export const getSingleData = (universityId, {
  universityAllFieldsById,
  parcelAreaByGrantState,
  parcelsByGrantState,
  paidToTribesByUniversity,
  raisedByUniversitiesByUniversity
}) => ({
  universityId,
  ...universityAllFieldsById[universityId],
  areaReceived: parcelAreaByGrantState[universityAllFieldsById[universityId].state],
  nParcelsReceived: parcelsByGrantState[universityAllFieldsById[universityId].state] && parcelsByGrantState[universityAllFieldsById[universityId].state].length,
  paidToTribes: paidToTribesByUniversity[universityId],
  raisedByUniversity: raisedByUniversitiesByUniversity[universityId]
});
export const getUniversityStats = ({
  openedYear,
  nStudents,
  nANAStudents,
  endowmentValue,
  type
}) => [[openedYear, 'University opened'], [nStudents, 'Students (2018)'], [nANAStudents, 'American Indian or Alaska Native Students (2018)'], ...(endowmentValue ? [[endowmentValue, 'Total university endowment (2018)']] : []), [type, 'University type']];
export const getUniversityGainsStats = ({
  grantReceivedYear,
  grantShare,
  areaReceived,
  paidToTribes,
  raisedByUniversity
}) => [[grantReceivedYear, 'Assigned land grant by state'], [<Percent mdxType="Percent">{grantShare}</Percent>, 'Share of Morrill Act grant'], [<Decimal mdxType="Decimal">{areaReceived}</Decimal>, 'Acres received'], [<Currency mdxType="Currency">{paidToTribes}</Currency>, 'U.S. Paid for Indigenous Title'], [<Currency mdxType="Currency">{raisedByUniversity}</Currency>, 'Endowment Principal Raised from Grant'], [<Multiple suffix=":1" mdxType="Multiple">{raisedByUniversity / paidToTribes}</Multiple>, 'Endowment return on payments to tribes', styles.textFigureWithBorder]];
export const remainMountedChildren = <>
    <Header mdxType="Header" />
    <StaticNavbar mdxType="StaticNavbar" />
    <Consumer render={({
    name
  }) => <FloatingNavbar anchorLinks={[{
    href: '#about',
    label: name
  }, {
    href: '#map',
    label: 'Map'
  }, {
    href: '#tribes-ceding-lands',
    label: 'Tribes Ceding Lands'
  }, {
    href: '#lands-given',
    label: `Tribes ceding land received by ${name}`
  }]} mdxType="FloatingNavbar" />} mdxType="Consumer" />
    <a name="about" />
    <Section containerWidth colProps={{
    className: styles.titleBar
  }} mdxType="Section">
      <Consumer render={({
      prevSlug,
      prevName
    }) => <Link className={c('dd-none d-desktop-flex', styles.prevLink)} href={`/universities/${prevSlug}`} mdxType="Link">
            <F icon={left} mdxType="F" />
            {prevName}
          </Link>} mdxType="Consumer" />
      <Consumer render={({
      sealImageFixed,
      name
    }) => <div className={styles.title}>
            {sealImageFixed ? <Img className={styles.titleImage} fixed={sealImageFixed} alt={`${name} seal`} mdxType="Img" /> : null}
            <h2>{name}</h2>
          </div>} mdxType="Consumer" />
      <Consumer render={({
      nextSlug,
      nextName
    }) => <Link className={c('dd-none d-desktop-flex', styles.nextLink)} href={`/universities/${nextSlug}`} mdxType="Link">
            {nextName}
            <F icon={right} mdxType="F" />
          </Link>} mdxType="Consumer" />
    </Section>
    <Section paragraphWidth mdxType="Section">
      <Consumer render={({
      city,
      stateAbbreviation
    }) => <p className={styles.subheading}>{`${city}, ${stateAbbreviation}`}</p>} mdxType="Consumer" />
      <div className={c('text-center', styles.uniStatement)}>
        University Statement
      </div>
      <Consumer render={({
      notesHTML
    }) => <p className={c('text-center', styles.uniNotes)} dangerouslySetInnerHTML={{
      __html: notesHTML
    }} />} mdxType="Consumer" />
    </Section>
    <Section figureWidth className="dd-none d-desktop-block mt-5 mb-5" colProps={{
    className: styles.textFigureRow
  }} mdxType="Section">
      <Consumer render={d => getUniversityStats(d).map(([figure, caption, figureStyles]) => <figure className={c(styles.textFigure, figureStyles)} key={caption}>
              {figure}
              <figcaption>{caption}</figcaption>
            </figure>)} mdxType="Consumer" />
    </Section>
    <Section className="dd-none d-desktop-block" paragraphWidth mdxType="Section">
      <p className={styles.subheading}>Gains from the Morrill Act</p>
    </Section>
    <Section figureWidth className="dd-none d-desktop-block mb-5" colProps={{
    className: styles.textFigureRow
  }} mdxType="Section">
      <Consumer render={d => getUniversityGainsStats(d).map(([figure, caption, figureStyles]) => <figure className={c(styles.textFigure, figureStyles, styles.figureStylesNarrow)} key={caption}>
              {figure}
              <figcaption>{caption}</figcaption>
            </figure>)} mdxType="Consumer" />
    </Section>
    <Section paragraphWidth mdxType="Section">
      <div className={c('text-center', styles.uniStatement)}>
        Notes on Land Revenues
      </div>
      <Consumer render={({
      disposalHTML
    }) => <p className={c('', '')} dangerouslySetInnerHTML={{
      __html: disposalHTML
    }} />} mdxType="Consumer" />
    </Section>
    <p>&nbsp;</p>
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4>
          {'Indigenous Land Granted to '}
          <Consumer render={({
      name
    }) => name} mdxType="Consumer" />
        </h4>} mapSubtitle="Morrill Act parcels and recipient schools" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  getSingleId,
  getSingleData,
  getUniversityStats,
  getUniversityGainsStats,
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = provideData(MDXPage, ['universitiesBySlug', 'universityAllFieldsById', 'parcelAreaByGrantState', 'parcelsByGrantState', 'paidToTribesByUniversity', 'raisedByUniversitiesByUniversity']);
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <Consumer render={({
      name
    }) => <PageMeta title={name} mdxType="PageMeta" />} mdxType="Consumer" />
    <a name="tribes-ceding-lands" />
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <Consumer render={({
        universityId
      }) => <FilterPhrase name="university-earned-from-parcels-in-state" university={universityId} mdxType="FilterPhrase" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Consumer render={({
        universityId
      }) => <Table name="tribes-for-selected-states-for-university" university={universityId} pageSize={10} mdxType="Table" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <p>
    <em>
      <b>NOTE</b>: Our data shows land cessions that are associated with
      multiple tribal nations. These totals add up to more than 100% of total
      acreage. The names above appear as they did at time of cession. Many are
      not in use today, and some are considered offensive. For more information,{' '}
      <a href="#about">see our methodology</a>.{' '}
    </em>
  </p>
  <p>&nbsp;</p>
    </Section>
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <Consumer render={({
        universityId
      }) => <FilterPhrase name="university-received-parcels-in-state" university={universityId} mdxType="FilterPhrase" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Consumer render={({
        universityId
      }) => <Table name="lands-for-selected-states-for-university" university={universityId} pageSize={10} mdxType="Table" />} mdxType="Consumer" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      